// stylelint-disable

// # Source Sans Pro

// ## Regular

// ### latin-ext
@import url("https://use.typekit.net/noh1rub.css");


//New Fonts
$font-futurapt-cond: 'futura-pt-condensed', sans-serif;
$font-weight: 500;
$font-style: normal;

$font-futurapt-cond-bold: 'futura-pt-condensed', sans-serif;
$font-weight: 700;
$font-style: normal;

$font-futurapt-book: 'futura-pt',sans-serif;
$font-weight: 400;
$font-style: normal;

$font-main: $font-futurapt-book;
$font-alt: $font-futurapt-cond;
$font-alt2: $font-futurapt-cond-bold;